// Interactive <image> element for React with loading animation for slow connections.
// Usage
// <Image url={url} className={desired style class} .../>

import React, { useEffect, useState } from 'react'
import Loader from '../Loader/Loader'
import './Image.css'

const Image = ({ url, className }) => {

  const[imgLoaded, setImgLoaded] = useState(false)

  // change state to be false when url is changed
  useEffect(() => {
    setImgLoaded(false)
  },[url])

  // hide img element, when it is not ready
  const styleLoaded = () => {
    if(imgLoaded) return {}
    else return { display: 'none' }
  }

  // Loader is shown when imgLoaded is false
  return(
    <>
      {!imgLoaded && <div className = 'img-place-holder'><Loader text='Loading image...'/></div>}
      <img src={url} className = {className} style = {styleLoaded()} onLoad={() => setImgLoaded(true)} />
    </>
  )
}
export default Image