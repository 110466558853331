import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSessions } from './reducers/rdc-vraps'
import AppHeader from './components/AppHeader/AppHeader'
import './vraps.css'
import './vraps-layout.css'
import Loader from '../../components/UI/Loader/Loader'
import Vrap from './components/Vrap'
import { setUnits } from './components/VrapUnits/reducers/rdc-vrapUnits'
import { s3GetSignUrlsForLatestSession } from '../../reducers/s3Reducer'

const Vraps = () => {

  const dispatch = useDispatch()
  const vrapsDb =          useSelector(state => state.users && state.vraps)
  const user =             useSelector(state => state.user)

  // states
  const [selectedID,          setSelectedID]            = useState(null)
  const [filteredVraps,       setFilterdVraps]          = useState(vrapsDb)
  const [bodyContainer,       setBodyContainer]         = useState(null)
  const [vraps,               setVraps]                 = useState(vrapsDb)

  const [searchWord, setSearchWord] = useState('')
  const s3signedUrls = useSelector(state => state.s3signedUrls)

  // get sessions
  useEffect(() => {
    user && dispatch(getSessions( user.token ))
    // reset filteredCustomers (needed when adding new customer to include it in array)
    vrapsDb && setFilterdVraps(vrapsDb)
  }, [user, dispatch, bodyContainer])

  // get Vrap units
  useEffect(() => {
    user && dispatch(setUnits( user.token ))
  },[user, dispatch, bodyContainer])

  // sorting vraps
  let sortedVraps
  if(Array.isArray(filteredVraps)) {
    sortedVraps = filteredVraps.slice(0,50)
  }
  // scroll to up, when arriving to site
  useEffect( () => {window.scrollTo(0, 0)},[bodyContainer])

  // getting signer urls for S3 objects
  useEffect(() => {
    vraps && dispatch(s3GetSignUrlsForLatestSession(vraps, user.token, s3signedUrls))
  },[vraps])

  // somehow vraps are not shown when page is loaded first time.

  setTimeout(() => {
    if(!vraps) {setVraps(vrapsDb)
      vrapsDb && searchWord === ''  && setFilterdVraps(vrapsDb)}
  },1000)

  return(
    vraps ?
      <div className="container">
        <AppHeader
          selectedID={selectedID}
          setFilterdVraps = {setFilterdVraps}
          filteredVraps = {filteredVraps}
          vraps = {vraps}
          bodyContainer = {bodyContainer}
          setBodyContainer = {setBodyContainer}
          searchWord = {searchWord}
          setSearchWord = {setSearchWord}
        />

        <div className = "flex-column-reverse mp-mt-20">
          {(Array.isArray(filteredVraps) && bodyContainer !== '0' && Array.isArray(filteredVraps) === true ) &&
          sortedVraps.map(vrap =>
            <div key={vrap.id}>
              <Vrap
                selectedID = {selectedID}
                setSelectedID = {setSelectedID}
                setBodyContainer  = {setBodyContainer}
                bodyContainer = {bodyContainer}
                setFilterdVraps = {setFilterdVraps}
                vraps = {vraps}
                vrap = {vrap}
              />
            </div>
          )}
        </div>
      </div>
      :
      <Loader/>
  )
}

export default Vraps
