import { useEffect } from 'react'

function findTime({ video, time, setTime, readyVideoState, fileUrl }){
  useEffect(() => {
    if(video) {
      video.currentTime = time[0]
    }
    else if(fileUrl !== 'noVideo' && readyVideoState > 3){
      video.currentTime = 0
    }
  },[time[1], setTime])
}
export default findTime