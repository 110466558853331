import React, { useEffect, useState } from 'react'
import dateToDayAndTime from '../../../../../../../functions/dateToDayAndTime'
import SpotErrorsForm from './SpotErrorsForm'
import SpottedErrors from './SpottedErrors'
import { useDispatch, useSelector } from 'react-redux'
import { getSessions } from '../../../../../reducers/rdc-vraps'
import dragElement from '../../../../../../../functions/dragElement'

const ErrorSpotting = ({
  sessionType, mouseX, mouseY, time, setTime, setMouseX, setMouseY, setReadyVideoState, setSelecterError, selectedError,
  activeRecord, sessionId, setActiveVideoId, activeVideoId, errorSpotted, setErrorSpotted, sizeMainVideo, setPlay, setReverse, setSwing }) => {

  const [errorType, setErrorType] = useState('')
  const [surfaceClass, setSurfaceClass] = useState('')
  const user = useSelector(state => state.user)
  const[popunInfoContent, setPopupInfoContent] = useState('')
  const dispatch = useDispatch()

  // Choose video handler
  const chooseVideoHandler = (e) => {
    setActiveVideoId(e.target.value)
    // set mouse to coords to 0
    setMouseX(0)
    setMouseY(0)
    setReadyVideoState(0)
    setPopupInfoContent(null)
  }
  // get sessions
  useEffect(() => {
    user && dispatch(getSessions( user.token ))
  }, [])

  // show add error -form or not
  let showForm = false
  if(time[0] !== 0 && ( mouseX + mouseY ) > 0 ) showForm = true
  else if(( mouseX + mouseY ) > 0 ) showForm = true
  else showForm = false

  // active video content
  const activeVideos = activeRecord.videos.filter(video => video.id === activeVideoId)
  const activeVideo = activeVideos[activeVideos.length - 1]

  // sort videos by date
  let sortedVideos
  try{
    sortedVideos = activeRecord.videos.length > 1
      ? activeRecord.videos.sort((a, b) => b.date.localeCompare(a.date))
      : activeRecord.videos
  }  catch(error)
  {
    sortedVideos = activeRecord.videos
    console.log(error)
  }


  // make element dragable
  // par1: elemnt to be dragged, par2: situation when barrier
  //<- when screen size is changed it prevents to make element leve outside of screen
  dragElement(document.getElementById('errorform'), sizeMainVideo !== 'vrap-videoMain-normal')


  return(
    <div className="vrap-component-container">
      {sessionType === 'Monitoring' &&
        <div>
          { activeRecord.videos.length > 0 &&
        <form>
          <select onChange={chooseVideoHandler} value={activeVideo && activeVideo.id}>
            {sortedVideos.map(function(video, i) {
              return(
                <option key={video.id} value={video.id}>{activeRecord.videos.length - i}. | {video.caption ? video.caption : 'Untitled video'} | {dateToDayAndTime(video.date)}</option>
              )
            }
            )}
          </select>
        </form>
          }
          <div id="errorform">
            { showForm && !errorSpotted &&

            <SpotErrorsForm
              mouseY = { mouseY }                 mouseX = { mouseX }
              surfaceClass = { surfaceClass }     setErrorType  = { setErrorType }
              setMouseY = { setMouseY }           setMouseX = { setMouseX }
              time = { time }                     sessionId = { sessionId }
              activeRecord = { activeRecord }     errorType = { errorType }
              activeVideoId = { activeVideoId }   setSurfaceClass = { setSurfaceClass }
              setReverse={setReverse}
              popunInfoContent = {popunInfoContent}  setPopupInfoContent = {setPopupInfoContent}
            />

            }
          </div>
        </div>

      }
      { <SpottedErrors
        activeVideo = {activeVideo}
        setMouseX = {setMouseX}
        setMouseY = {setMouseY}
        setTime = {setTime}
        setErrorSpotted = {setErrorSpotted}
        recordId = {activeRecord.id}
        selectedError = {selectedError}
        setSelecterError = {setSelecterError}
        setPlay = {setPlay}
        setReverse = {setReverse}
        setSwing = {setSwing}
      />}
    </div>
  )
}

export default ErrorSpotting



