import React, { useEffect, useState } from 'react'
import Loader from '../Loader/Loader'
import './Video.css'

const Video = ({ className, src, preload, id, muted, loop, onClick, reverse, play, swing }) => {

  const donShowLoading = () => {
    if(reverse || play || swing) return true
    else return false
  }
  const pollRate = () => {
    if(document.getElementById('mainVideo') && document.getElementById('mainVideo').readyState < 4){
      return 500
    }
    else if(!document.getElementById('mainVideo')) { return 1000 }
    else if(document.getElementById('mainVideo').readyState > 3){return 99999}
    else return 99999
  }
  const[poll, setPoll] = useState(0)
  let videoLoaded = true
  useEffect(() => {
    const interval = setInterval(() => {
      // doing something
      setPoll(poll+1)

    },pollRate())
    //Clearing the interval
    return () => clearInterval(interval)
  }, [document.getElementById('mainVideo'), pollRate, poll])

  if(document.getElementById('mainVideo') && document.getElementById('mainVideo').readyState > 2){
    videoLoaded = true
  } else {
    videoLoaded = false
  }

  return(
    <>
      <video
        className={className}
        src={src}
        preload={preload}
        controls = {false}
        id = {id}
        muted={muted}
        loop={loop}
        onClick={onClick}
      >
      </video>
      {/* This is not somehow not working on iPhone - so hiding it in mobile*/}
      {!videoLoaded && !donShowLoading() &&  <div className="video-loader-container"><Loader text='Loading video...'/></div>}
    </>
  )
}
export default Video