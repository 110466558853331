
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MeasureData from './components/MeasureData/MeasureData'
import SessionMonitor from './components/SessionMonitor/SessionMonitor'
import SessionFooter from './components/SessionFooter'

// USING SocketIO chat
import VrapChat from './components/sio-VrapChat/VrapChat'
// [depricated] USING REST chat
// import VrapChat from './compoents/VrapChat/VrapChat'

import Session3Camera from './components/Session3Camera'
// import VideoControls from './components/VideoControls/VideoControls'
import Session2Camera from './components/Session2Camera'
// import SessionMainCamera from './components/SessionMainCamera'
import { postNewRecord } from '../../../reducers/rdc-vraps'
import ErrorSpotting from './components/ErrorSpotting/ErrorSpotting'
import VideoList from './components/VideoList'
import { setNotification } from '../../../../../components/UI/Notification/notificationReducer'
import SessionHeader from './components/SessionHeader'
import { s3GetSignUrlsForLatestSession } from '../../../../../reducers/s3Reducer'
import SessionMainVideo from './components/SessionMainVideo'
// import getSignedUrls from './components/utils/getSignedUrls'
// import Poll from '../../../../../utilities/Poll'
function lastIn(arr){ return arr[arr.length-1] }

const Session = () => {
  const dispatch = useDispatch()

  // const units = useSelector(state => state.user && state.vrapUnits)
  const sessions = useSelector(state => state.vraps)
  const user = useSelector(state => state.user)
  const token = user.token
  const [modal, setModal] = useState(false)
  const [play, setPlay] = useState(false)
  const [sizeMainVideo, setSizeMainVideo] = useState('vrap-videoMain-normal')
  const [size2Video, setSize2Video] = useState('vrap-video2-normal')
  const [size3Video, setSize3Video] = useState('vrap-video3-normal')
  const [sizeMeasures, setsizeMeasures] = useState('vrap-measures-normal')
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const [time, setTime] = useState([0,''])
  const [errorSpotted, setErrorSpotted] = useState(false)
  const [readyVideoState, setReadyVideoState] = useState(true)
  const[selectedError, setSelecterError] = useState(null)
  const s3signedUrls = useSelector(state => state.s3signedUrls)
  const vraps =          useSelector(state => state.users && state.vraps)
  const[reverse, setReverse] = useState(false)
  const[swing, setSwing] = useState(false)


  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 2)

  // filter current session by id
  const session = sessions.filter(session => session.id === sessionId)[0]

  // getting signer urls for S3 objects
  // This is douple request sinse urls:s might get old during the time. Maybe first req in vraps.js is not nesesseary?
  // getting signer urls for S3 objects
  useEffect(() => {
    vraps && dispatch(s3GetSignUrlsForLatestSession(vraps, user.token, s3signedUrls, sessionId))
  },[])

  const theme = useSelector(state => state.theme)
  // find out type of module
  // A = monitoring
  // B = production
  // is defined as first character before id number in window.location
  const [sessionType, setSessionType] = useState(url.substring(idIndex + 1).charAt(0) === 'A' ? 'Monitoring' : 'Production')

  // state for active record ID
  const[activeRecordId, setActiveRecordId] = useState(
    session && session.vrapSessionRecords.length > 0
      // if record(s) exists
      ? lastIn(session.vrapSessionRecords).id
      : '' // else mark it empty string
  )
  const activeRecord = session ? session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0] : []

  // set active video, pick the last one
  const [activeVideoId, setActiveVideoId] = useState('')
  useEffect(() => {
    !activeVideoId && setActiveVideoId(session && activeRecord.videos.length > 0
      ? activeRecord.videos[0].id
      : null)
  }, [activeRecordId])

  // add a new record modal
  const NewRecordModal = () => {
    const[newCaption, setNewCaption] = useState('')
    const recordBody = { caption: newCaption }
    // add new event handler
    const newRecordHandler = () => {
      dispatch(postNewRecord(token, session.id, sessions, recordBody))
      setModal(false)
      dispatch(setNotification({ notification: `New Piolot Test Run has been added with caption: ${newCaption}` }))
    }
    return(
      <div className = "vrap-modal">
        <h3>Add a new test run</h3>
        <p>When you start a new test series on a product with new parameters.</p>
        <label>Caption for a test run</label>
        <input value={newCaption} onChange={(e) => setNewCaption(e.target.value)}/>
        <div className="flex-row">
          <button className = "buttonGreen" onClick={newRecordHandler}>Add</button>
          <button onClick={() => setModal(false)}>Cancel</button>
        </div>
      </div>
    )

  }
  // const [sizeMeasures, setsizeMeasures] = useState('vrap-measures-normal')
  let hideMain = '', hideVideo2 = '', hideVideo3 = '', hideFooter = '', hideChat='', hideMeasures = '', groupSStyle = {}
  if(sizeMainVideo !== 'vrap-videoMain-normal'){
    hideVideo2 = ' hide '
    hideVideo3 =  ' hide '
    hideFooter = ' hide '
    hideChat = ' hide '
    hideMeasures = ' hide '
    hideMain = ''
    groupSStyle = { width: '100%' }
  }
  if(size2Video !== 'vrap-video2-normal'){
    hideVideo3 = ' hide '
    hideVideo2 = ''
    hideMain = ' hide '
    hideFooter = ' hide '
    hideChat = ' hide '
    hideMeasures = ' hide '
  }
  if(size3Video !== 'vrap-video3-normal'){
    hideVideo2 = ' hide '
    hideVideo3 = ''
    hideMain = ' hide '
    hideFooter = ' hide '
    hideChat = ' hide '
    hideMeasures = ' hide '
  }
  if(sizeMeasures !== 'vrap-measures-normal'){
    hideVideo2 = ' hide '
    hideMain = ''
    hideFooter = ' hide '
    hideChat = ' hide '
    hideMeasures = ''
  }

  return(
    <>
      { session ?
        <div>
          { modal && <NewRecordModal/> }
          <div className= {'vrap-layout-grp-master ' + theme}>
            <div className = 'vrap-layout-grp-header'>

              {/*----------  Header -------------- */}
              { sizeMainVideo === 'vrap-videoMain-normal' &&
                size2Video === 'vrap-video2-normal' &&
                size3Video === 'vrap-video3-normal' &&
                sizeMeasures === 'vrap-measures-normal' &&

                <SessionHeader
                  activeRecordId = { activeRecordId }
                  sessionId = {sessionId}
                  session = { session }
                  sessionType = { sessionType }
                  activeRecord = { activeRecord }
                  setActiveRecordId = { setActiveRecordId }
                  setActiveVideoId = { setActiveVideoId }
                  setModal = { setModal }
                  setSessionType = { setSessionType }
                  s3signedUrls s= {s3signedUrls}
                  vraps = {vraps}
                />}

            </div>
            <div className="vrap-layout-grp-body">
              {size3Video === 'vrap-video3-normal' && size2Video === 'vrap-video2-normal' &&
              <div className="vrap-layout-grp-a" style={groupSStyle}>

                {/* Main Video */}
                <div className= { 'flex-center ' + sizeMainVideo + hideMain }>
                  { sessionType === 'Production' ?
                    <SessionMainVideo
                      sizeMainVideo= {sizeMainVideo}
                      setSizeMainVideo = {setSizeMainVideo}
                      id = {sessionId}
                      sessionType = {sessionType}
                      activeRecordId = {activeRecordId}
                    /> :
                    <SessionMonitor
                      activeRecord = {activeRecord}
                      mouseX = {mouseX}
                      mouseY = {mouseY}
                      setMouseY = {setMouseY}
                      setMouseX = {setMouseX}
                      time = {time}
                      setTime = {setTime}
                      activeVideoId = {activeVideoId}
                      setErrorSpotted = {setErrorSpotted}
                      sizeMainVideo = {sizeMainVideo}
                      setSizeMainVideo = {setSizeMainVideo}
                      setActiveVideoId = {setActiveVideoId}
                      readyVideoState = {readyVideoState}
                      setReadyVideoState = {setReadyVideoState}
                      errorSpotted = {errorSpotted}
                      selectedError = {selectedError}
                      play = {play}
                      setPlay = {setPlay}
                      vraps = {vraps}
                      user = {user}
                      reverse = {reverse}
                      setReverse={setReverse}
                      swing = {swing}
                      setSwing = {setSwing}

                    />
                  }
                </div>
                {/* ERROR SPOTTING */}
                <div className = {'vrap-error-spotting ' + theme}>

                  { sessionType === 'Monitoring' ?
                    <ErrorSpotting
                      setSwing = {setSwing}
                      sessionType = {sessionType}
                      activeRecordId = {activeRecordId}
                      activeRecord = {activeRecord}
                      mouseX = {mouseX}
                      mouseY = {mouseY}
                      setMouseY = {setMouseY}
                      setMouseX = {setMouseX}
                      setTime = {setTime}
                      time = {time}
                      activeVideoId = {activeVideoId}
                      setActiveVideoId = {setActiveVideoId}
                      sessionId = {sessionId}
                      setErrorSpotted = {setErrorSpotted}
                      errorSpotted = {errorSpotted}
                      setReadyVideoState = {setReadyVideoState}
                      selectedError = {selectedError}
                      setSelecterError = {setSelecterError}
                      setPlay = {setPlay}
                      setReverse={setReverse}
                    /> :
                    <VideoList
                      activeRecord = {activeRecord}
                      activeVideoId = {activeVideoId}
                      setActiveVideoId = {setActiveVideoId}
                      sessionId = {sessionId}
                      activeRecordId = {activeRecordId}
                      sessions = {sessions}
                      setSessionType = {setSessionType}
                    />
                  }
                </div>



              </div>}
              <div className="vrap-layout-grp-b">
                {/* title for mobile view, not visible in wide screen*/}
                <div className = "vrap-h-mobile"><h3>Process video</h3></div>
                {/* process video (video 2) */}
                <div className= {size2Video + ' ' + hideVideo2}>
                  <Session2Camera
                    sessionId= {sessionId}
                    setSize2Video = {setSize2Video}
                    size2Video = {size2Video}
                    sessionType = {sessionType}
                    activeRecordId = {activeRecordId}
                    session = {session}
                    sessions = {sessions}
                    s3signedUrls = {s3signedUrls}
                  />
                </div>

                {/* title for mobile view, not visible in wide screen*/}
                <div className = "vrap-h-mobile"><h3>Parameters</h3></div>

                {/* parameter Image - VIDEO 3 */}
                <div className= { 'flex-center ' + size3Video + hideVideo3}>
                  <Session3Camera
                    sessionId = {sessionId}
                    setSize3Video = {setSize3Video}
                    size3Video = {size3Video}
                    sessionType = {sessionType}
                    activeRecordId = {activeRecordId}
                    session = {session}
                    sessions = {sessions}
                    s3signedUrls = {s3signedUrls}
                  />
                </div>

                {/* title for mobile view, not visible in wide screen*/}
                <div className = "vrap-h-mobile"><h3>Quality Check-up Report</h3></div>

                {/* Measure Data - vrap-bottom-measures*/}
                <div className = {sizeMeasures + hideMeasures + ' ' + theme}>
                  <MeasureData
                    sizeMeasures = {sizeMeasures}
                    setsizeMeasures = {setsizeMeasures}
                    sessionId = {sessionId}
                    sessionType = {sessionType}
                    activeRecordId = {activeRecordId}
                    session = {session}
                    sessions = {sessions}
                    s3signedUrls = {s3signedUrls}
                  />
                </div>

              </div>
              <div className="vrap-layout-grp-c">
                {/* title for mobile view, not visible in wide screen*/}
                <div className = "vrap-h-mobile"><h3>Chat</h3></div>
                {/* Chat */}
                <div className= {'vrap-chat ' + hideChat + theme}>
                  <VrapChat id = {session.id} token = {token} />
                </div>
              </div>

            </div>
          </div>
          <div className={'.vrap-layout-grp-footer ' + hideFooter}>
            <SessionFooter
              activeRecord={activeRecord}
              session={session}
              sessions = {sessions}
              token = {token}
            />

          </div>
        </div>
        :
        <></>
      }

    </>

  )
}

export default Session



