import { useEffect } from 'react'

function forwardAction({ video,forward, playBackRate, swing }){
// -------- interval for forward ---------
  useEffect(() => {
  //Implementing the setInterval method
    const interval = setInterval(() => {
      video.currentTime = nextFrame()
    }, (forward) ? 100 : 999999999)
    //Clearing the interval
    return () => clearInterval(interval)
  }, [forward, swing])


  function nextFrame(){
    if(video){
      if(video.currentTime + 0.0333 * playBackRate < video.duration) return video.currentTime + 0.0333 * playBackRate
      else return 0
    }
  }
}
export default forwardAction