import { s3GetMany, s3GetSingle } from '../services/s3Service'

const s3Reducer = (state = [], action) => {
  switch(action.type){
  case 'SET_S3_SIGNED_URLS':
    return action.data

  default: return state
  }
}

export const s3GetSignedUrl = (token, fileUrl, signedUrls) => {
  return async dispatch => {
    s3GetSingle(token, fileUrl).then(response => {
      dispatch({
        type: 'SET_S3_SIGNED_URLS',
        data: signedUrls.concat([response.data])
      })
    })
      .catch(error => {console.log(error)})
  }
}


// GET SIGNED URLS FOR LAST SESSION WHEN SIGNIN UP ON PAGE
export const s3GetSignUrlsForLatestSession = (vraps, token, s3signedUrls, vrapId) => {

  let urls = []
  let body
  // how long signed ulrs are valid
  const lifetime = 3600000

  // getting urls just for latest session
  const lastVrap = !vrapId ? vraps[vraps.length - 1] : vraps.filter(vrap => vrap.id === vrapId)[0]
  lastVrap.vrapSessionRecords.map(record => {
    if(record.parametersUrl && record.parametersUrl !== 'notSet') {urls.push(record.parametersUrl)}
    if(record.measuresUrl && record.measuresUrl !== 'notSet') {urls.push(record.measuresUrl)}
    if(record.processVideoUrl && record.processVideoUrl !== 'notSet') {urls.push(record.processVideoUrl)}
    record.videos.length > 0 && record.videos.map(video => {
      if(video.url !== 'notSet') {urls.push(video.url)}
    })
  })
  // time now in milliseconds
  const timeNow = new Date().getTime()

  // find all outdated signedUrls
  const outdatedSignedUrls = s3signedUrls && s3signedUrls.length > 0 ? s3signedUrls.filter(url => timeNow - url.date > lifetime) : []
  const validSignedUrls =  s3signedUrls && s3signedUrls.length > 0 ? s3signedUrls.filter(url => timeNow - url.date <= lifetime): []

  // if signedUrls already existed ask only for outdated urls
  if(s3signedUrls && s3signedUrls.length > 0 && outdatedSignedUrls.length > 0)
    body = { items: outdatedSignedUrls.map( url => { return url.url} ) }

  // else get them all
  else if (s3signedUrls && s3signedUrls.length > 0 && outdatedSignedUrls.length < 1)
    body = null
  else body = { items: urls }

  return async dispatch => {
    console.log('body: ', body)
    const newSignedUrsData = body && body.items.length > 0 ? await s3GetMany(token, body) : []
    const newSignedUrs = newSignedUrsData ? newSignedUrsData.data : []

    let updatedSignedUrls
    // if user is coming first time on component, get all signed urls of last session
    if(!s3signedUrls || s3signedUrls.length < 1 ) updatedSignedUrls = newSignedUrs
    // concat updated signed Urls to old existing valit ones
    else if(validSignedUrls && newSignedUrs && newSignedUrs.length > 0 && validSignedUrls.length > 0) updatedSignedUrls = validSignedUrls.concat(newSignedUrs)
    // if all valid signed urls are old...
    else if(validSignedUrls && newSignedUrs && newSignedUrs.length > 0 && validSignedUrls.length < 1) updatedSignedUrls = newSignedUrs
    // Nothin gto update, all signed urls are valid
    else if(s3signedUrls && s3signedUrls.length > 0 && newSignedUrs && newSignedUrs.length < 1) { updatedSignedUrls = s3signedUrls }
    // this might be without reason...
    else updatedSignedUrls = s3signedUrls

    dispatch({
      type: 'SET_S3_SIGNED_URLS',
      data: updatedSignedUrls !== undefined ? updatedSignedUrls : {}
    })
  }
}

export default s3Reducer