// edited using:
// https://www.w3schools.com/howto/ho
// function to make element dragable
// give for element id that meets value of elmnt

function dragElement(elmnt, barrier) {

  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0
  if (elmnt && document.getElementById(elmnt.id + 'header')) {
  // if present, the header is where you move the DIV from:
    document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
  } else if(elmnt){
  // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown
  }

  function dragMouseDown(e) {

    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag
  }
  // when chancing to full screen object can be landed outside of window, so barrier for that:
  if(elmnt && barrier && (elmnt.offsetTop - pos2) < 0) elmnt.style.top = 0 + 'px'
  function elementDrag(e) {
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + 'px'
    elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px'
  }

  function closeDragElement() {
  // stop moving when mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }
}
export default dragElement