function styleForCommentBox({ video, mouseX, videoHeight, offsetY, headerHeight,mouseY }){
  // styles for infobox of comments top of the video
  let commentsStyle = { left: '10px', top: '5px' }
  if(video && (video.offsetWidth - video.offsetWidth * mouseX) < 300){
    commentsStyle.left = '-110px' }

  if(video && (video.offsetHeight + headerHeight + offsetY - window.scrollY/videoHeight) - video.offsetHeight * mouseY < 300){
    commentsStyle.top = document.getElementById('cursorBox') ? -1 * document.getElementById('cursorBox').offsetHeight - 5 + 'px' : '-110px'}
  return commentsStyle
}
export default styleForCommentBox