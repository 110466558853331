function findFileUrl(activeVideoId, activeRecord){
  const lastIn = (arr) => arr.at(-1)
  let fileUrl
  if(!activeVideoId && activeRecord.videos.length > 0){
    try{
      fileUrl = lastIn(activeRecord.videos).url
    } catch(err){
      console.log(err)
      fileUrl = 'noVideo'
    }
  }
  else if(activeVideoId && activeRecord.videos.length > 0)
    fileUrl = activeRecord.videos.filter(video => video.id === activeVideoId)[0].url
  else fileUrl = 'noVideo'
  return fileUrl
}
export default findFileUrl