// -------------- DEFINE VIDEO SIZE WHEN WINDOW SIZE IS CHANGED --------------------------------
// polling is making window to render every every 250 ms. So also red defect spot is going to have
// recalculated position to be

import { useEffect, useState } from 'react'

function setVideoSize({
  setTrackingSpeed, mouseX, mouseY, setMouseY, setMouseX, video, reverse,
  setVideoWidth, setVideoHeight, setErrorSpotted, play, time, mouseOnHeader
}){

  let trackingSpeed
  if(mouseX > 0 || mouseY > 0 || play || mouseOnHeader || reverse) trackingSpeed = setTrackingSpeed
  else trackingSpeed = 999999999

  const[poll, setPoll] = useState(0)
  useEffect(() => {
    // increas value of poll in every round
    const interval = setInterval(() => {
      setPoll(poll+1)
    }, (poll < 50000) ? trackingSpeed : 999999999)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [poll, trackingSpeed])

  // alert if polling is over
  if(poll >= 50000) alert('please refres the page to continue')

  useEffect(() => {
    if(video && !play && !video.paused){
      video.pause()
      video.currentTime = time[0]
    }
    setVideoWidth(document.getElementById('mainVideo') ? document.getElementById('mainVideo').offsetWidth : 0)
    setVideoHeight(document.getElementById('mainVideo') ? document.getElementById('mainVideo').offsetHeight: 0)
    if(video && !video.paused){
      setErrorSpotted(false)
      setMouseY(0)
      setMouseX(0)
    }
  }, [poll])
}
export default setVideoSize