import React, { useEffect, useState } from 'react'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import dateToDay from '../../../../../../functions/dateToDay'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ButtonTxtIcon from '../../../../../../components/UI/ButtonTxtIcon/ButtonTxtIcon'
import HelpPop from '../../../../../../components/UI/HelpPop/HelpPop'
import { useDispatch, useSelector } from 'react-redux'
import { getSessions } from '../../../../reducers/rdc-vraps'
import { s3GetSignUrlsForLatestSession } from '../../../../../../reducers/s3Reducer'
import { getVrapsPolled } from '../../../../reducers/rdc-vraps-polled'
const SessionHeader = ({ activeRecordId, session, sessionType, setActiveRecordId,
  setActiveVideoId, setModal, setSessionType, sessionId,
  s3signedUrls, vraps }) => {
  const history = useHistory()
  const help = useSelector(state => state.help.show)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const[showUpdates, setShowUpdates] = useState(false)

  const sessionsPolled = useSelector(state => state.vrapsPolled)
  const sessionPolled = sessionsPolled && sessionsPolled.filter(session => session.id === sessionId)[0]
  const activeRecordPolled = sessionPolled && sessionPolled.vrapSessionRecords.filter(record => record.id === activeRecordId)[0]

  const sessionsComp = useSelector(state => state.vraps)
  const sessionComp = sessionsComp && sessionsComp.filter(session => session.id === sessionId)[0]
  const activeRecordComp = sessionComp && sessionComp.vrapSessionRecords.filter(record => record.id === activeRecordId)[0]

  // number of differences between used session record state and state in database
  let isEqual = 0
  let differences = []
  let i_defects_comp = 0
  let i_defects_polled = 0
  if(activeRecordComp && activeRecordPolled){
    if(activeRecordPolled.parametersUrl !== activeRecordComp.parametersUrl) {
      isEqual++
      differences = ['parameter image']
    }
    if(activeRecordPolled.processVideoUrl !== activeRecordComp.processVideoUrl) {
      isEqual++
      differences =[...differences, 'Process video']
    }
    if(activeRecordPolled.measuresUrl !== activeRecordComp.measuresUrl) {
      isEqual++
      differences += 'measures PDF'
    }

    if(activeRecordPolled.comments !== activeRecordComp.comments) {
      isEqual++
      differences = [...differences, 'comments']

    }
    if(activeRecordPolled.videos.length !== activeRecordComp.videos.length) {
      isEqual++
      differences = [...differences, 'approval videos']

    }
    activeRecordPolled.videos.map((video) => {
      // just find out if same url exists in current database.
      const hasSameVideo = activeRecordComp.videos.filter(vid => vid.url === video.url )
      if(hasSameVideo.length < 1) {
        isEqual++
        differences = [...differences, 'approval video']

      }
    })
    if(sessionPolled.vrapSessionChats.length !== sessionComp.vrapSessionChats.length) {
      differences = [...differences, 'Chat is out of sync']
    }

    activeRecordComp.videos.map(v => v.defects.map(() => { i_defects_comp++}))
    activeRecordPolled.videos.map(v => v.defects.map(() => {i_defects_polled++}))
    if(i_defects_polled !== i_defects_comp) {
      isEqual++
      differences = [...differences, 'defect marks']

    }
  }
  const Differences = () => {
    return(
      <>
        <h4 style={{ textAlign: 'center' }}>Updates available in:</h4>
        <ul>
          {differences.map( diff =>
            <li key = {diff}>{diff}</li>
          )}
        </ul>
        <small>You can apply the changes by clicking reload button</small>
      </>
    )
  }


  const selectHandle = (e) => {
    setActiveRecordId(e.target.value)
    setActiveVideoId(null)
  }

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      // get polled vraps to compare
      user && dispatch(getVrapsPolled( user.token, user.company ))
    }, 10000)
    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  // reload button hanler to refresh vraps state
  const reloadVrapsHandler = async  () => {
    const lastIn = (arr) => arr.at(-1)
    // get sessions
    await dispatch(getSessions( user.token )).then(
      () => {
        if(vraps && s3signedUrls){
          dispatch(s3GetSignUrlsForLatestSession(vraps, user.token, s3signedUrls, sessionId))
        }
        setTimeout(() => {setActiveVideoId(lastIn(activeRecordPolled.videos).id)}, 500)
      }

    )

  }

  return(
    <div id="vrap-header" className="vrap-layout-grp-header">

      {/*   --------------ADD A NEW TEST RUN BUTTON ------------------------*/}

      <div className='flex-row mp-ml-10'>

        {/* Reload button */}
        <div className = "vrap-reload-button-container">
          <div>
            <IconButton
              char = 'd'
              text= 'Reload'
              onClick={reloadVrapsHandler}
              className = 'icon-button-secondary'
              size="30px"
            />
          </div>

          {/* updates available info */}
          {isEqual > 0 &&
            <div
              className = "vrap-update-sign"
              onMouseEnter = {() => setShowUpdates(true)}
              onMouseLeave = {() => setShowUpdates(false)}
            >{isEqual}
              {showUpdates && <div className = "vrap-differences-popup" ><Differences/></div>}
            </div>}
        </div>

        {/* Add new testrun BUTTON*/}

        <ButtonTxtIcon icon='D'
          onClick={() => setModal(true)}
          className='buttonGreen'
          label = 'New run'
        />
        {/*  help Pop  */}
        {help && <HelpPop title="Add new test run" text="When you start a new series on a product with new parameters."/>}

        {/*   -------------- SESSION NAME | TEST RUN CAPTION ------------------------*/}

        <div className = 'flex-row'>
          {/* <b>{session.sessionName}</b> */}
          <div className='mp-mb-5 flex-row'>

            {/* ------------------- SELECT TESTRUN ----------------------------------*/}

            <select value = {activeRecordId} onChange={selectHandle}>
              {
                session.vrapSessionRecords.map((record, index) => {
                  return(
                    <option value = {record.id} key={record.id}>
                        Test run {index + 1 + ' • ' + dateToDay(record.date)}
                    </option>
                  )})}
            </select>
            {help && <HelpPop
              text="When you open the page, the most recent test run is displayed by default. You can watch older runs here"
              title = "Recorded test runs"
            />}
          </div>


        </div>
      </div>
      <div className='flex-row mp-p-10'>

        {/*   -------------- LINK TO BOTTOM OF PAGE------------------------*/}
        <IconButton
          char = 'e'
          text= 'Comments'
          onClick={() => {window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })}}
          className = 'icon-button-secondary'
          size="30px"
        />
        {/*   -------------- Switch to PRODUCTION / MONITORING BUTTON------------------------*/}
        <IconButton
          char = {sessionType === 'Monitoring' ? 'c' : 'k' }
          text= {'Switch to ' + (sessionType === 'Monitoring' ? 'production' : 'monitoring') }
          onClick={() => setSessionType(sessionType === 'Monitoring' ? 'Production' : 'Monitoring')}
          className = 'icon-button-secondary'
          size="30px"
        />
        {/*   -------------- EXIT SESSION BUTTON------------------------*/}
        <IconButton
          char = "x"
          text='Exit session'
          onClick={ () => {history.push('/vraps')} }
          className = 'icon-button-secondary'
          size="30px"
        />
      </div>
    </div>

  )

}
export default SessionHeader