import { useEffect } from 'react'

function swingAction({ setforward, setReverse, swing }){
  let i_swing = 1
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      i_swing = -i_swing
      if(i_swing < 0) {
        setforward(false)
        setReverse(true)
      } else {
        setforward(true)
        setReverse(false)
      }
    }, swing ? 1000 : 999999999)
    //Clearing the interval
    return () => clearInterval(interval)
  }, [swing])
}export default swingAction
