import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

// -------------------- CORE MODULE IMPORTS -------------------------------------
// USER(S)
import userReducer from './reducers/userReducer'
import usersReducer from './reducers/usersReducer'
import invitedUserReducer from './reducers/invitedUserReducer'
import invitedUsersReducer from './reducers/invitedUsersReducer'
import userTrashcanReducer from './reducers/userTrashcanReducer'

import filtersReducer from './reducers/filtersReducer'



// HELP
import helpReducer from './components/UI/Help/helpReducer'

// TODO
import toDosReducer from './Apps/ToDo/reducers//todosReducer'

// THEME

import themeReducer from './reducers/themeReducer'


// LANGUAGES
// import langReducer from './reducers/langReducer'

// NOTIFICATION
import notificationReducer from './components/UI/Notification/notificationReducer'

// import modalReducer from './reducers/modalReducer'
import simpleModalReducer from './components/UI/Modal/simpleModalReducer'

// Company
import companyReducer from './reducers/companyReducer'

// S3
import s3Reducer from './reducers/s3Reducer'
// -------------------- APPS IMPORTS-------------------------------------
// MESSAGES
import threadsReducer from './Apps/Messages/MessageReducers/threadsReducer'
import newPostReducer from './Apps/Messages/MessageReducers/newPostReducer'
import msgScrollReducer from './Apps/Messages/MessageReducers/msgScrollReducer'
import msgPollReducer from './Apps/Messages/MessageReducers/msgPollReducer'

// VRAP
import getVraps from './Apps/Vraps/reducers/rdc-vraps'
import vrapUnitsReducer from './Apps/Vraps/components/VrapUnits/reducers/rdc-vrapUnits'
import vrapsReducerPolled from './Apps/Vraps/reducers/rdc-vraps-polled'





const persistConfig = {
  key: 'root',
  storage,
}



const reducer = combineReducers({
  // -----------------  CORE MODULES STORES  ------------------------------
  // USER(S)
  user: userReducer,
  users: usersReducer,
  invitedUser: invitedUserReducer,
  invitedUsers: invitedUsersReducer,
  deletedUsers: userTrashcanReducer,

  // TODO
  toDos: toDosReducer,

  help: helpReducer,

  filters: filtersReducer,

  // LANG
  // lang: langReducer,

  // THEME
  theme: themeReducer,

  // OTHER
  notification: notificationReducer,
  // modal: modalReducer,
  simpleModal: simpleModalReducer,

  // Companies
  companies: companyReducer,

  // S3
  s3signedUrls: s3Reducer,

  //------------------ APPS STORES --------------------------------------------
  // MESSAGES
  threads: threadsReducer,
  newPost: newPostReducer,
  scrollType: msgScrollReducer,
  msgPoll: msgPollReducer,

  // VRAPS
  vraps: getVraps,
  vrapUnits: vrapUnitsReducer,
  vrapsPolled: vrapsReducerPolled
})

const rootReducer = (state, action) => {
  // Clears store on logout
  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
)
const persistor = persistStore(store)
const stores = { store, persistor }

export default stores