import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { vrapGetSingleUrl } from '../../../../../services/srvSessionRecord'
import Video from '../../../../../../../components/UI/Video/Video'
import findFileUrl from './findFileUrl'
import findFile from './findFile'
import findActiveVideoId from './findActiveVideoId'
import swingAction from './swingAction'
import reverseAction from './reverseAction'
import forwardAction from './forwardAction'
import findTime from './findTime'
import SessionMonitorHeader from './SessionMonitorHeader'
import ErrorCursorAndBox from './ErrorCursorAndBox'
import setVideoSize from './setVideoSize'

const SessionMonitor = ({
  activeRecord, setMouseX, setMouseY, mouseX, setActiveVideoId, readyVideoState, errorSpotted,
  mouseY, time, setTime, activeVideoId, setErrorSpotted, setSizeMainVideo, sizeMainVideo, selectedError,
  play, setPlay, reverse, setReverse, setSwing, swing
}) => {
  const video = document.getElementById('mainVideo')

  const [videoWidth, setVideoWidth] = useState(video ? video.offsetWidth : window.innerWidth/2)
  const [videoHeight, setVideoHeight] = useState(video ? video.offsetHeight: window.innerHeight/2)
  const signedUrls = useSelector(state => state.s3signedUrls)
  const[playBackRate, setPLayBackRate] = useState(1)
  const[forward, setforward] = useState(false)
  const[file, setFile] = useState()
  const [mouseOnHeader, setMouseOneHeader] = useState(false)


  // reset playback settings when changing observed video
  useEffect(() => {
    setPLayBackRate(1)
    setPlay(false)
    setReverse(false)
  },[activeVideoId])

  // offset is because of header height
  let offsetY = 40
  if(sizeMainVideo === 'vrap-videoMain-normal') { offsetY = 40 }

  // if window is enlarged controllers will be fixed on top of the screen
  let styleFixed
  if(sizeMainVideo !== 'vrap-videoMain-normal') styleFixed = { position: 'fixed' }

  const token = useSelector(state => state.user.token)

  // ------- find out active video url ---------------------------
  const fileUrl = findFileUrl(activeVideoId, activeRecord)

  // find file and if not existing update signedUrls state
  findFile({ signedUrls, fileUrl, vrapGetSingleUrl, token, setFile })

  // find active video´s id
  findActiveVideoId({ setActiveVideoId, activeVideoId, activeRecord })

  // find out if there are defect to show
  const errorCont =  (activeVideoId && selectedError && errorSpotted) ?
    activeRecord.videos.filter(video => video.id === activeVideoId)[0].
      defects.filter(defect => defect._id === selectedError)[0] : ''

  //------- Define error by mouse click, get time and current mouse coords
  const headerHeight = document.getElementById('vrap-header') ? document.getElementById('vrap-header').offsetHeight : 0
  const defineErrorHandler = (e) => {
    e.preventDefault()
    setTime([document.getElementById('mainVideo').currentTime, Date()])
    setMouseX(e.clientX / videoWidth)
    setMouseY((e.clientY - offsetY - headerHeight + window.scrollY)/(videoHeight))
    setErrorSpotted(false)
    setPlay(false)
    setforward(false)
    setSwing(false)
    setReverse(false)
    document.getElementById('mainVideo').pause()
  }

  // -------------- FIND TIME IN VIDEO --------------------------------
  findTime({ video, time, setTime, readyVideoState, fileUrl })


  // contains setTimeout function to refresh video size which also makes React to rerender screen
  // to replace defect cursor.

  // how maky ms frecuensy is new video size defined
  const setTrackingSpeed = 100

  setVideoSize({
    setTrackingSpeed, mouseX, mouseY, setMouseY, setMouseX, video, setVideoWidth,
    setVideoHeight, setErrorSpotted, play, time, mouseOnHeader, reverse
  })

  // ------------- PLAY BUTTON HANDLE ----------------------------------

  const playButtonHandle = () => {
    // oscDirection = 0
    if(video){
      if(!play){
        video.play()
        setPlay(true)
      } else {
        video.pause()
        setPlay(false)
      }
      setReverse(false)
      setMouseX(0)
      setMouseY(0)
      setErrorSpotted(true)
      setSwing(false)
      setforward(false)
    }
  }

  // function to make toggle rew - forv for video
  swingAction({ setforward, setReverse, swing })

  // --------------Swing button handle ---------------
  const swingHandle = () => {
    setPlay(false)
    setReverse(false)
    setforward(false)
    video.pause()
    setSwing(swing ? false : true)
  }

  // ------------- REVERSE handle  -------------
  const reverseHandle = () => {
    video.pause()
    setPlay(false)
    setReverse(!reverse ? true : false)
    setMouseX(0)
    setMouseY(0)
    setErrorSpotted(true)
    setforward(false)
    setSwing(false)
  }
  // function to go reverse
  reverseAction({ reverse, video, playBackRate, swing })

  // function to go forward
  forwardAction({ video,forward, playBackRate, swing })

  // playback rate | speed
  const setPlayBackRateHandle = (e) => {
    setPLayBackRate(e.target.value)
    video.playbackRate = e.target.value
  }


  return(
    <>
      <div className="vrap-video-inner-box">
        {fileUrl !== 'noVideo' ?
          <>
            {/* ----- HEADER ------- */}
            { video  &&

            <SessionMonitorHeader
              sizeMainVideo = {sizeMainVideo}   setSizeMainVideo = {setSizeMainVideo}              styleFixed = {styleFixed}
              setVideoWidth = {setVideoWidth}   setVideoHeight = {setVideoHeight}                  reverse = {reverse}
              swing = {swing}                   reverseHandle = {reverseHandle}                    swingHandle = {swingHandle}
              play={play}                       playButtonHandle = {playButtonHandle}              video = {video}
              playBackRate = {playBackRate}     setPlayBackRateHandle = {setPlayBackRateHandle}    setMouseOneHeader = {setMouseOneHeader}
            />
            }
            {/*--------  VIDEO PLAYBACK ( #t=0.1 to preview about the first frame)  ---------------*/}
            <Video
              src={file + '#t=0.1'}             preload="metadata"          id = "mainVideo"
              controls = {false}                video = {video}             className={'vrap-main-video-preview'}
              muted={true}                      loop={true}                 onClick={defineErrorHandler}
              reverse={reverse}                 play = {play}               swing={swing}
            />

            <ErrorCursorAndBox
              videoWidth = {videoWidth}         mouseX = {mouseX}           videoHeight = {videoHeight}
              mouseY = {mouseY}                 offsetY= {offsetY}          headerHeight = {headerHeight}
              errorSpotted = {errorSpotted}     errorCont = {errorCont}     video = {video}
              setErrorSpotted={setErrorSpotted} setMouseY={setMouseY}       setMouseX={setMouseX}
            />
          </>
          // ------ if no video, show infoTxt ( could be added reload button ) -------------
          : file === '' &&
            <p>No video set yet</p>
        }
      </div>
    </>
  )
}

export default SessionMonitor






