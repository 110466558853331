import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import VideoButtons from './VideoControls/VideoButtons'
import Loader from '../../../../../../components/UI/Loader/Loader'
import { postMainVideo } from '../../../../reducers/rdc-vraps'
import VrapForm from './VrapForm'
import HWForm from './hwForm'

const SessionMainCamera = ({ id, setSizeMainVideo, sizeMainVideo, activeRecordId }) => {
  const units = useSelector(state => state.user && state.vrapUnits)
  const sessions = useSelector(state => state.vraps)
  const token = useSelector(state => state.user.token)
  const [loading, setLoading] = useState(false)
  const[sendFile, setSendFile] = useState(false)
  const[hwModal, setHwModal] = useState(false)

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 1)

  // but if it is child component it uses value from props
  if(id !== undefined) { sessionId = id }

  const vrap = sessions.filter(session => session.id === sessionId)[0]

  // find out current active Unit
  const currentUnit = units.filter(unit => unit.id === vrap.unit)[0]

  // video sources
  const theSrc = currentUnit.mainVideoSource

  useEffect( () => {setLoading(false)}, [sessions])

  return(

    <>
      { hwModal &&
        <HWForm currentUnit={currentUnit} setHwModal={setHwModal}/>
      }
      { sendFile &&

      <VrapForm setLoading={setLoading} sessions={sessions} activeRecordId={activeRecordId}
        sessionId = {sessionId} dispatchAction={postMainVideo} acceptFormat = 'video/*'
        setSendFile = {setSendFile} formLabel = 'CHOOSE A VIDEO FILE TO UPLOAD'
      />
      }
      {loading && <Loader title = "Dowloading image"/>}
      <div className="vrap-video-inner-box-MainVideo flex-center">
        <iframe id = "iframe-main-video" src={theSrc} title="Production Video" className="vrap-video-preview">{theSrc}</iframe>
      </div>
      <VideoButtons
        currentUnit = {currentUnit}
        token = {token}
        activeRecordId = {activeRecordId}
        setSizeMainVideo = {setSizeMainVideo}
        sizeMainVideo = {sizeMainVideo}
        sendFile = {sendFile}
        setSendFile = {setSendFile}
        hwModal = {hwModal}
        setHwModal = {setHwModal}
      />

    </>

  )
}

export default SessionMainCamera



