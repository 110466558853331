/* eslint-disable no-unused-vars */
import dateToDayAndTime from '../../../../../../functions/dateToDayAndTime'
import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { deleteVideo, putMainVideo } from '../../../../reducers/rdc-vraps'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import getSignedUrls from './utils/getSignedUrls'
import { setNotification } from '../../../../../../components/UI/Notification/notificationReducer'

const VideoList = ({ activeRecordId, sessionId, setSessionType, setActiveVideoId  }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const sessions = useSelector(state => state.vraps)

  const deleteVideoHandler = (id) => {
    dispatch(setNotification({
      notification: 'Confirm to delete video!', // title
      type: 'modal',
      onClickConfirm: () => {
        dispatch(deleteVideo(token, activeRecordId, id))
      }
    }
    ))
  }
  const session = sessions.filter(session => session.id === sessionId)[0]
  const activeRecord = session.vrapSessionRecords.filter(record => record.id === activeRecordId)[0]
  // sort videos to be reversed by date
  let sortedVideos
  try{
    sortedVideos = activeRecord && activeRecord.videos.length > 1
      ? activeRecord.videos.sort((a, b) => b.date.localeCompare(a.date))
      : activeRecord.videos
  }  catch(error)
  {
    sortedVideos = activeRecord.videos
    console.log(error)
  }
  const TableContent = () => {
    if(sortedVideos) return(
      sortedVideos.map(function(video, i) {
        const [newCaption, setNewCaption] = useState(video.caption)

        const updateHandler = (e) => {
          e.preventDefault()
          const body = { caption: newCaption }
          dispatch(putMainVideo(token, sessionId, activeRecordId, video.id , sessions, body))
        }
        const videoClickHandler = (id) => {
          setActiveVideoId(id)
          setSessionType('Monitoring')
        }
        return(
          <tr key={video.id} className="videolist">
            <td className="hover-pointer" onClick={() => videoClickHandler(video.id)}>
              <video
                className="vrap-video-thumb"
                controls = {false}
                src={getSignedUrls(video.url) + '#t=0.1'}
                preload="metadata"
              ></video></td>
            <td>{activeRecord.videos.length - i}</td>
            <td>{dateToDayAndTime(video.date)}</td>
            <td colSpan='2'>
              <form onSubmit={updateHandler} className = "flex-row">
                <input className = "vrap-videolist-input"
                  value={newCaption}
                  onChange={(e) => setNewCaption(e.target.value)}/>
                <IconButton
                  className = 'icon-button-main'
                  char="u" text="Update"
                  size = '30'
                  type="submit"
                />
                <IconButton
                  onClick={() => deleteVideoHandler(video.id)}
                  className = 'icon-button-secondary'
                  char="M" text="Delete"
                  size = '30'
                />
                {/* <button className = 'icon-symbol icon-button-main' type="submit">u</button> */}
              </form>
            </td>
          </tr>
        )

      }
      ))
    else return <div>Problem</div>
  }


  return(
    <div className="vrap-component-container">
      <table>
        <thead>
          <tr className="table-head">
            <th>Video</th>
            <th></th>
            <th>Date</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <TableContent/>
        </tbody>
      </table>
    </div>
  )
}

export default VideoList



