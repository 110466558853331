import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import Title from '../../../UI/Title/Title'
// import langs from '../../../langs'
import UiCard from '../../../UI/UiCard/UiCard'
import Button from '../../../UI/Button/Button'
import './addAvatar.css'
// import { s3uploadImage } from '../../../../reducers/s3UploadReducer'

const AddAvatar = () => {

  // const user = useSelector(state => state.user)
  // const lN = user.language // lN = languageNumber <- 0 EN, 1 FI ...
  // const trans = langs.lang // translation
  // const dispatch = useDispatch()

  const [file, setFile] = useState(null)
  const uploadFile = (event) => {
    alert('Molule is on list TODO...', event)
  }

  return(
    <>
      <Title text="Avatar" icon="Y"/>
      <UiCard
        headerTitle = "Lisää / vaihda avatar"
      >
        <div className="gap-20"/>
        <form className="UI-card-body-container">
          <label className="button-upload buttonGreen btn-icon add">Valitse kuva
            <input accept="image/*" name = "valitse kuva" type="file" onChange={(event) => setFile(event.target.files[0])}></input>
          </label>
          <p className="add-avatar-p">
            {file !== undefined &&
              <>
                <img className="avatar-info-icon" src='info.svg'/>
                <span>Kuva valittu – klikkaa lähetä!</span>
              </>}
          </p>
          <Button label = "Lähetä" type="buttonGreen" icon = "update" onClick={uploadFile}/>
        </form>
      </UiCard>
    </>
  )
}

export default AddAvatar