const decriptions = [
  {
    defect: 'Sink mark',
    description: 'A small crater or depression on the part surface, especially on thicker areas.',
    causes: 'Faulty mold, excessive injection pressure or insufficient clamping force.',
    imgSrc: 'sink-mark.png',
    inspection: 'Visual'
  },
  {
    defect: 'Flash',
    description: 'Excess material on the part surface e.g. on split line or ejector pin location',
    causes: 'Faulty mold, excessive injection pressure or insufficient clamping force.',
    imgSrc: 'flash.png',
    inspection: 'Visual or measuring'
  },
  {
    defect: 'Short Shot',
    description: 'The part form is not complete.',
    causes: 'Cavities not filled completely caused by insufficient gas venting or incorrect process parameters.',
    imgSrc: 'short-shot.png',
    inspection: 'Visual'
  },  {
    defect: 'Weld Lines',
    description: 'A line-like error, usually extending from hole or opening in the part.',
    causes: 'Flow fronts from different directions meet and bonding is inadequate.',
    imgSrc: 'weld-line.png',
    inspection: 'Visual or testing'
  },{
    defect: 'Burn marks',
    description: 'Blackened or burnt area on part surface.',
    causes: 'Too high temperature or injection speed or poor gas venting.',
    imgSrc: 'burn.png',
    inspection: 'Visual'
  },{
    defect: 'Silver Line (Splay marks)',
    description: 'Silver colored “shiny” stripes or dots in the surface.',
    causes: 'Humidity contained in raw material evaporates in the mold.',
    imgSrc: 'silver-line.png',
    inspection: 'Visual'
  },{
    defect: 'Blister (Porosity)',
    description: 'Pores inside the part that appears as surface defects.',
    causes: 'Evaporation of impurities in raw material or incorrect processing.',
    imgSrc: 'blister.png',
    inspection: 'Visual'
  },{
    defect: 'Flow Lines',
    description: 'Colour tone differences on the surface along the flow path of plastic.',
    causes: 'Humidity in raw material, faulty material, partial solidification during flow.',
    imgSrc: 'flow-lines.png',
    inspection: 'Visual'
  },{
    defect: 'Color Tone',
    description: 'Deviation from defined color or tones.',
    causes: 'Incorrect material, faulty color mixing, faulty gate design, wrong process parameters.',
    imgSrc: 'color-tone.png',
    inspection: 'Visual comparison to sample parts or measured tone deviation between manufactured part and sample',
    note: 'A batch of sample parts with variable color tone must be made prior manufacturing from which a sample with desired tone along with limit samples (lighter and darker) is chosen by GELECTA or CUSTOMER. These samples define the allowed color tone. Note: If color tone measuring device (spectrophotometer or colorimeter) is available, allowed values for measured color tone deviation can be agreed between GELECTA or CUSTOMER and manufacturer and be used as acceptance criteria during inspection.'
  },
  {
    defect: 'Surface Texture',
    description: 'Deviation from defined surface texture or gloss.',
    causes: 'Temperature differences, mold worn out, impurities in mold.',
    imgSrc: 'surface-teksture.png',
    inspection: ' Visual, comparison to sample surface'
  },
  {
    defect: 'Surface Delamination',
    description: 'Thin coating-like layer peels off from part surface.',
    causes: 'Humidity or contamination in raw material, wrong process parameters.',
    imgSrc: 'delamination.png',
    inspection: 'Visual'
  },{
    defect: 'Embedded particles',
    description: 'Visible foreign particles attached to the part surface e.g. dust, grease etc.',
    causes: 'Contamination in raw material, dust or other impurities in mold.',
    imgSrc: 'embedded-particles.png',
    inspection: 'Visual'
  },{
    defect: 'Refraction Error',
    description: 'Light distortion in transparent part.',
    causes: 'Mold defect or incorrect molding process.',
    imgSrc: 'refraction-error.png',
    inspection: 'Visual'
  },{
    defect: 'Jetting',
    description: 'Wavy pattern on the part surface, usually near gate point.',
    causes: 'Too high injection speed, faulty injection gate location, too low temperature.',
    imgSrc: 'jetting.png',
    inspection: 'Visual'
  },{
    defect: 'Scratches',
    description: 'Small scratches, grooves, cuts or abrasion on the part surface.',
    causes: 'Contact with foreign objects, faulty design, improper handling of parts.',
    imgSrc: 'scratches.png',
    inspection: 'Visual'
  },{
    defect: 'Other',
    description: 'Please descripe defect in Comments field.',
    causes: 'Please descripe defect in Comments field.',
    inspection: 'Please descripe defect in Comments field.'
  }

]
export default decriptions
