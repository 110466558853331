import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../../../components/UI/Loader/Loader'
import { postMainVideo } from '../../../../reducers/rdc-vraps'
import VrapForm from './VrapForm'
import ButtonTxtIcon from '../../../../../../components/UI/ButtonTxtIcon/ButtonTxtIcon'

const SessionMainVideo = ({ id, activeRecordId }) => {
  // const units = useSelector(state => state.user && state.vrapUnits)
  const sessions = useSelector(state => state.vraps)
  // const token = useSelector(state => state.user.token)
  const [loading, setLoading] = useState(false)
  const[sendFile, setSendFile] = useState(false)
  const [progress, setProgress] = useState()

  // find out the id of current session using window.location
  const url = window.location.href
  const idIndex = url.lastIndexOf('/')
  let sessionId = url.substring(idIndex + 1)

  // but if it is child component it uses value from props
  if(id !== undefined) { sessionId = id }

  // const vrap = sessions.filter(session => session.id === sessionId)[0]

  // find out current active Unit
  // const currentUnit = units.filter(unit => unit.id === vrap.unit)[0]

  // video sources


  useEffect( () => {setLoading(false)}, [sessions])
  let progreeInfoTxt
  if(progress < 100 && loading) progreeInfoTxt = 'Uploading video'
  else if(progress === 100 && loading) progreeInfoTxt = 'Uploaded! - waiting for response from server'

  return(

    <>
      { !loading && <ButtonTxtIcon icon='D'
        onClick={() => setSendFile(true)}
        className='buttonGreen mp-mt-30'
        label = 'Upload a new approval video'
      />}
      { sendFile &&
      <VrapForm setLoading={setLoading} sessions={sessions} activeRecordId={activeRecordId}
        sessionId = {sessionId} dispatchAction={postMainVideo} acceptFormat = 'video/*'
        setSendFile = {setSendFile} formLabel = 'CHOOSE A VIDEO FILE TO UPLOAD'
        progress = {progress} setProgress = {setProgress}
      />
      }
      {loading && <Loader title = {progreeInfoTxt} progress={progress}/>}


    </>

  )
}

export default SessionMainVideo



