import { useEffect } from 'react'

function reverseAction({ reverse, video, playBackRate, swing }){
  // -------- interval for reverse ---------
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      video.currentTime = prewFrame()
    }, (reverse) ? 100 : 999999999)
    //Clearing the interval
    return () => clearInterval(interval)
  }, [reverse, swing])

  function prewFrame(){
    if(video){
      if(video.currentTime - 0.0333 * playBackRate > 0){
        return video.currentTime - 0.0333 * playBackRate}
      else return video.duration
    } else return 0
  }
}

export default reverseAction