import React, { useState } from 'react'
import decriptions from './descriptions'
import Image from '../../../../../../../components/UI/Image/Image'
// defectInfoHandler(defectType, currentDefect.description)
function ErrorDescriptions ({ defect, defectInfoHandler, setErrorType }) {

  const [currentDescription, setCurrentDescription] = useState(decriptions.filter(def => def.defect === defect)[0])
  const ListOfAllDefects = () => {
    return(
      <select
        value={currentDescription.defect}
        onChange={(e) => {
          setCurrentDescription(decriptions.filter(def => def.defect === e.target.value)[0])
          setErrorType(e.target.value)
          defectInfoHandler(e.target.value, decriptions.filter(def => def.defect === e.target.value)[0].description)
        }}>
        {
          decriptions.map(def => <option key={def.defect}>{def.defect}</option>)
        }
      </select>
    )
  }

  const imgUrl = '/vraps/img/' + currentDescription.imgSrc

  return(
    <>
      <div className = "vrap-defect-description-container">
        <ListOfAllDefects/>
        {currentDescription.description && <p><strong>Definition: </strong>{currentDescription.description}</p>}
        {currentDescription.causes && <p><strong>Possible causes: </strong>{currentDescription.causes}.</p>}
        {currentDescription.imgSrc && <Image url={imgUrl} className = "vrap-infopopup-img"/>}
        {currentDescription.inspection && <p>Inspection: {currentDescription.inspection}</p>}
        {currentDescription.notes && <p><strgon>Notes:</strgon> {currentDescription.notes}</p>}
      </div>
    </>
  )
}
export default ErrorDescriptions