import { useEffect } from 'react'

function findActiveVideoId({ setActiveVideoId, activeVideoId, activeRecord }){
  const lastIn = (arr) => arr.at(-1)
  useEffect(() => {
    setActiveVideoId(
      !activeVideoId && activeRecord.videos.legth > 0 ? lastIn(activeRecord.videos).id
        : activeVideoId
    )
  },[activeRecord])
}

export default findActiveVideoId