import React from 'react'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import round from '../../../../../../../functions/round'

function SessionMonitorHeader({
  sizeMainVideo, setSizeMainVideo, styleFixed, setVideoWidth, setVideoHeight, reverse,
  swing, reverseHandle, swingHandle, play, playButtonHandle, setPlayBackRateHandle, playBackRate,
  video, setMouseOneHeader
}){

  return(
    <div
      className = "vrap-video-monitoring-ctrls-container"
      style={styleFixed}
      onMouseEnter={() => setMouseOneHeader(true)}
      onMouseLeave={() => setMouseOneHeader(false)}
    >
      {/* ---------- REVERSE BUTTON ---------- */}
      <IconButton
        className = 'icon-button-secondary hide-in-mobile'
        size = "30px"
        char={sizeMainVideo === 'vrap-videoMain-normal' ? '4' : '3'}
        text={sizeMainVideo === 'vrap-videoMain-normal' ? 'Enlarge' : 'Shrink'}
        onClick={ () => {
          setSizeMainVideo( sizeMainVideo === 'vrap-videoMain-normal' ? 'vrap-videoMain-enlarged' : 'vrap-videoMain-normal')
          setVideoWidth(window.innerWidth)
          setVideoHeight(window.innerHeight)
        }}
      />

      {/* ---------- REVERSE BUTTON ---------- */}
      <IconButton
        className = "icon-button-secondary"
        char={(reverse && !swing) ? 'B' : (reverse && !swing) ? 'B' : 'q'}
        text="Revind"
        onClick={reverseHandle}
        size = "30px"
      />

      {/* ---------- Swing button ---------- */}
      <IconButton
        className = "icon-button-secondary"
        char={swing ? 'B' : '2'}
        text="Revind"
        onClick={swingHandle}
        size = "30px"
      />

      {/* ------------- PLAY BUTTON ------------ */}
      <IconButton
        className = "icon-button-secondary"
        char={play ? 'B' : 'r'}
        onClick={ playButtonHandle }
        text={play ? 'Play' : 'Pause'}
        size = "30px"
      />
      <div className = "vrap-play-back-rate" >
        <input type="range" onChange={setPlayBackRateHandle} min="0.25" max="4" value={playBackRate}  step="0.25" />
        <div style={{ width: '60px' }}>{playBackRate}x</div>
      </div>
      <div className = "vrap-video-range-input" >
        <input type="range" onChange={(e) => {video.currentTime = e.target.value}} min="0" max={video.duration} value={video.currentTime}  step="0.05" />
        {video && round(video.currentTime,2) }s
      </div>
    </div>
  )
}

export default SessionMonitorHeader