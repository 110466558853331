import { useEffect } from 'react'

function findFile({ signedUrls, fileUrl, vrapGetSingleUrl, token, setFile }){
  useEffect(() => {
    if(signedUrls && signedUrls.length > 0 && fileUrl && fileUrl !== 'noVideo'){

      if(!signedUrls.filter(item => item.url === fileUrl)[0]){
        if(fileUrl !== 'noVideo'){
          vrapGetSingleUrl(token, fileUrl).then(response => {
            setFile(response.data)
          })
            .catch(error => {
              console.log(error)
            })
        }

      } else {
        const newFile = signedUrls.filter(item => item.url === fileUrl)[0].signedUrl
        setFile(newFile)
      }
    }
  },[fileUrl])
}

export default findFile