// form to upolad image / video / pdf
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const VrapForm = ({ setLoading, sessions, activeRecordId, sessionId,
  setSendFile, dispatchAction, acceptFormat, formLabel, setProgress }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const [fileToUpload, setFileToUpload] = useState(null)
  const sendFile = async (e) => {
    e.preventDefault()
    const recordId = activeRecordId
    const file = fileToUpload

    if(fileToUpload){
      setLoading(true)
      setSendFile(false)
      await dispatch(dispatchAction({ token, sessionId, recordId, file, sessions, setProgress }))
      setLoading(false)
    } else {alert('Please select file first')}
  }

  return(
    <div className="vrap-parameter-form" >
      <form onSubmit={sendFile}>
        <label>{formLabel}</label>
        <hr/>
        <div>
          <input type="file" name="video" accept={acceptFormat} onChange={(e) => setFileToUpload(e.target.files[0])}/>
          <div className = "flex-row-space-btw">
            <button className = "buttonGreen" type="submit">Upload</button>
            <button onClick={ () => setSendFile(false)}>Cancel</button>
          </div>
        </div>
      </form>
    </div>

  )
}

export default VrapForm



