// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addVideoError } from '../../../../../reducers/rdc-vraps'
import TextArea from '../../../../../../../components/UI/TextArea/TextArea'
import ErrorDescriptions from './ErrorDescriptions'
import Button from '../../../../../../../components/UI/Button/Button'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import decriptions from './descriptions'

const SpotErrorsForm = ({ setSurfaceClass, mouseX, setErrorType, mouseY,
  errorType, surfaceClass, time, setMouseY, setMouseX, setReverse,
  sessionId, activeRecord, activeVideoId, popunInfoContent, setPopupInfoContent
}) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const sessions = useSelector(state => state.vraps)
  const [errorComments, setErrorComments] = useState('')

  // add error handler
  const addErrorHandler = (e) => {
    e.preventDefault()

    // simple validate
    let ok = false
    if(errorType && surfaceClass) ok = true
    else alert('Please fill error type and surface!')

    // send error
    if(ok){
      const body = {
        errorLocation: [ mouseX, mouseY ],
        frame: time[0],
        errorType: errorType,
        surfaceClass: surfaceClass,
        time: Date(),
        errorComments: errorComments
      }
      // addVideoError = (token, body, sessionId, vrapItems, recordId, videoId) => {
      dispatch(addVideoError(token, body, sessionId, sessions, activeRecord.id, activeVideoId))
      setSurfaceClass()
      setErrorType('')
      setMouseX(0)
      setMouseY(0)
      setPopupInfoContent(null)
    }
  }

  // hadler to cnacel error form
  const cancelHandler = () => {
    setMouseX(0)
    setMouseY(0)
    setPopupInfoContent(null)
  }


  const SurvaceClassfications = () => {
    return(
      <>
        <img className = "vrap-infopopup-img" style={{ width: '300px' }} src='/vraps/img/classes.png'/>
        <h4>Class 1: Direct Visual Surface</h4>
        <p>
        A direct visual surface means that this is the surface exposed and directly visible to the customer in normal use (E.g. outer surfaces of covers). This surface will receive the most critical attention and therefore defects on this surface are to be kept at a minimum.
        </p>
        <h4>Class 2: Indirect Visual Surface</h4>
        <p>
        This surface refers to the secondary surface and the external surface of accessories that are not directly seen in normal use (E.g. side shell, rear shell) and thus require good visual quality.
        </p>
        <h4>Class 3: Non-Visual Surface</h4>
        <p>
        This surface is not visible in normal use and has no particular visual requirements (E.g. bottom shell, battery box, CD door). If the part is an internal part, then all the surfaces will be deemed class 3.
        </p>
      </>
    )
  }

  const showPopupInfo = (e) => {
    setPopupInfoContent(e)
    setReverse(false)
  }

  const headerHeight = document.getElementById('vrap-header') ? document.getElementById('vrap-header').offsetHeight : 0
  const videoHeight = document.getElementById('mainVideo').offsetHeight
  const mouseLocation = (headerHeight + videoHeight - 120) * mouseY
  const [defectInfo, setDefectInfo] = useState('')

  const defectInfoHandler = (defectType, txt) => {
    return(
      setDefectInfo(<div className="hover-pointer" onClick={() => setPopupInfoContent(<ErrorDescriptions setErrorType = {setErrorType} defectInfoHandler = {defectInfoHandler} defect={defectType}/>)}>{txt}</div>)
    )
  }
  const selectDefectTyptHandler = (e) => {
    const defectType = e.target.value
    setErrorType(defectType)
    const currentDefect = defectType !== '' ? decriptions.filter(desc => desc.defect === defectType)[0] : null
    defectInfoHandler(defectType, currentDefect ? currentDefect.description : null)
  }

  // eslint-disable-next-line no-unused-vars
  const formLocation = document.getElementById('errorformheader') ? document.getElementById('errorformheader').offsetTop : 0

  return(
    <div className="vrap-parameter-form" style={{ top:  mouseLocation - videoHeight - headerHeight + 100 + 'px' }}>
      <div id="errorformheader">
        <form className = "" onSubmit={addErrorHandler}>
          <h5 className="mp-ml-5">Mark a new surface defect</h5>
          {/* <p className = "vraps-subtitle"> X: {Math.round(mouseX * 100)}% Y: {Math.round(mouseY * 100)}% | at {Math.round(time * 100) / 100 } s.</p> */}
          {/* <label>Defect type:</label> */}
          <select onChange={selectDefectTyptHandler} value={errorType}>
            <option value="">Select a defect type</option>
            {decriptions.map(desc => <option key={desc.defect} value={desc.defect}>{desc.defect}</option>)}
          </select>
          <div>{defectInfo}</div>

          <label>Comments</label>
          <TextArea trickers={errorComments} value = {errorComments} onChange={(e) => {setErrorComments(e.target.value)}}/>

          <div className="flex-row flex-start mp-ml-5 mp-mt-10">
            <div className="hover-pointer" onClick={() => showPopupInfo(SurvaceClassfications)}>Surface class:</div>
            <div onClick={() => {setSurfaceClass(1); setPopupInfoContent(null)} } className = { surfaceClass === 1 ? 'vrap-class-select-button-selected' : 'vrap-class-select-button'}>1</div>
            <div onClick={() => {setSurfaceClass(2); setPopupInfoContent(null)} } className = { surfaceClass === 2 ? 'vrap-class-select-button-selected' : 'vrap-class-select-button'}>2</div>
            <div onClick={() => {setSurfaceClass(3); setPopupInfoContent(null)} } className = { surfaceClass === 3 ? 'vrap-class-select-button-selected' : 'vrap-class-select-button'}>3</div>
          </div>

          <div className = "buttons-row mp-p-5">
            <button className = "buttonGreen -dark-button" type="submit">Add</button>
            <button onClick= {cancelHandler} className = "button -dark-button" type="button">Cancel</button>
          </div>
          {popunInfoContent &&
          <div className = "vrap-popupInfo">
            <div className = "flex-row" style={{ justifyContent: 'flex-end' }}>
              <IconButton
                className = "icon-button-secondary"
                char="x"
                text="Close"
                onClick={() => {setPopupInfoContent(null)}}
                size = "30px"
              />
            </div>
            {popunInfoContent}
            <div className = "buttons-row">
              <Button  type="btn-action msg-btn-update" icon="send" label = "OK" onClick={() => {setPopupInfoContent(null)}}/>
            </div>
          </div>
          }
        </form>
      </div>
    </div>

  )
}
export default SpotErrorsForm