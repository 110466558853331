import { vrapsGet } from '../services/srvVraps'

const vrapsReducerPolled = (state = null, action) => {
  switch (action.type) {
  case 'SET_VRAPS_POLLED':
    return action.data
  default: return state
  }
}

// get vraps / sessions
export const getVrapsPolled = (token, company) => {
  return async dispatch => {
    let vraps = await vrapsGet(token, company)
    if(vraps.data === null) {
      vraps = { data: 'noAccountYet' }
    }
    dispatch({
      type: 'SET_VRAPS_POLLED',
      data: vraps.data
    })
  }
}
export default vrapsReducerPolled
