import React from 'react'
import styleForCommentBox from './styleForCommentBox'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'

function ErrorCursorAndBox({ setErrorSpotted, videoWidth, video, mouseX, videoHeight, mouseY, setMouseY, setMouseX, offsetY, errorSpotted, errorCont, headerHeight }){
  const commentsStyle = styleForCommentBox({ video, mouseX, videoHeight, offsetY, headerHeight, mouseY })

  const exitErrorHandler = () => {
    setErrorSpotted(false)
    setMouseY(0)
    setMouseX(0)
  }

  return(
    <div
      id="vrap-error-cursor"
      style={{
        left: videoWidth * mouseX -5,
        top: videoHeight * mouseY -5 + offsetY,
        opacity: (mouseX + mouseY) > 0 ? 1 : 0
      }}
    >
      {errorSpotted && errorCont &&
              <div id = 'cursorBox' className="vrap-error-txt-on-screen" style={commentsStyle}>
                <div className="vrap-error-box-exit">
                  <IconButton
                    char = 'x'
                    text= 'Reload'
                    onClick={exitErrorHandler}
                    className = 'icon-button-secondary mp-mb-5'
                    size="15px"
                  />
                </div>
                <div className='mp-mb-5'><b>{errorCont.errorType}</b></div>
                Class: {errorCont.surfaceClass}<br/>
                {errorCont.errorComments && <><b>Comments</b><br/>{errorCont.errorComments}</>}
              </div>}
    </div>
  )
}
export default ErrorCursorAndBox