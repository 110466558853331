import React, { useEffect, useState } from 'react'
import './measuredata.css'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import { postMeasureImage } from '../../../../../reducers/rdc-vraps'
import Loader from '../../../../../../../components/UI/Loader/Loader'
import VrapForm from '../VrapForm'
import getSignedUrls from '../utils/getSignedUrls'


const MeasureData = ({ sizeMeasures, session, setsizeMeasures, s3signedUrls, sessionId, activeRecordId, sessionType, sessions }) => {

  const[sendFile, setSendFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState()
  const [file, setFile] = useState()

  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]

  // get signed url using url recorded in db
  // get signed url using url recorded in db
  const seekedUrl = activeRecord.parametersUrl
  let fileToBe = getSignedUrls(activeRecord.measuresUrl)

  // checking if there is new image. First try if signedUrl exits, if not leaving newSignUrl null -> useEffect
  // will not trigger
  let newSignUrl
  try {
    newSignUrl = s3signedUrls.filter(url => url.url === seekedUrl)[0].signedUrl
  }
  catch(e){
    console.log('no sign url...')
  }

  useEffect(() => {
    setFile(fileToBe)
  },[newSignUrl && newSignUrl])

  let progreeInfoTxt
  if(progress < 100 && loading) progreeInfoTxt = 'Uploading PDF'
  else if(progress === 100 && loading) progreeInfoTxt = 'Uploaded! - waiting for response from server'


  // !! pdf embedded by object is freesing Safari.
  return(
    <div className="vrap-video-inner-box">
      { sessionType !== 'Monitoring' && !loading && sendFile &&
        <>
          <VrapForm setLoading={setLoading} sessions={sessions} activeRecordId={activeRecordId}
            sessionId = {sessionId} dispatchAction={postMeasureImage} acceptFormat = 'application/pdf'
            setSendFile = {setSendFile} formLabel = 'Choose a PDF file to upload'
            progress = {progress} setProgress = {setProgress}
          />
        </>

      }
      {
        sessionType !== 'Monitoring' && loading &&
          <Loader progress = {progress} text= {progreeInfoTxt}/>
      }

      {activeRecord.measuresUrl !== 'notSet' && !loading &&
        <>
          <iframe border="0" cellSpacing="0" className="vrap-pdf" src={file} width = "100%" height="100%"/>
        </>
      }
      {activeRecord.measuresUrl === 'notSet' && !loading &&
        <div className="flex-center" style={{ height: '100%' }}>
          <p>QC report PDF not set yet</p>
        </div>
      }

      <div className={'vrap-session-backButton-bottom'}>
        <IconButton
          className = 'icon-button-secondary hide-in-mobile'
          char={sizeMeasures === 'vrap-measures-normal' ? '5' : '3'}
          text={sizeMeasures === 'vrap-measures-normal' ? 'Enlarge' : 'Shrink'}
          onClick={ () => setsizeMeasures( sizeMeasures === 'vrap-measures-normal' ? 'vrap-measures-enlarged' : 'vrap-measures-normal')}
        />
        { sessionType === 'Production' ?
          <IconButton
            className = 'icon-button-secondary'
            char="1" text="Upload"
            onClick={() => setSendFile(sendFile ? false : true)}
          /> : <div></div>}
      </div>

    </div>


  )
}

export default MeasureData



