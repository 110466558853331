import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import { postParameterImage } from '../../../../reducers/rdc-vraps'
import VrapForm from './VrapForm'
import getSignedUrls from './utils/getSignedUrls'
import Image from '../../../../../../components/UI/Image/Image'
import Loader from '../../../../../../components/UI/Loader/Loader'

const Session3Camera = ({ sessionId, setSize3Video, size3Video, activeRecordId, session, sessionType, s3signedUrls }) => {
  const sessions = useSelector(state => state.vraps)
  const[sendFile, setSendFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState()
  const [file, setFile] = useState()
  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]

  // get signed url using url recorded in db
  const seekedUrl = activeRecord.parametersUrl
  let fileToBe = getSignedUrls(seekedUrl)

  // checking if there is new image. First try if signedUrl exits, if not leaving newSignUrl null -> useEffect
  // will not trigger
  let newSignUrl
  try {
    newSignUrl = s3signedUrls.filter(url => url.url === seekedUrl)[0].signedUrl
  }
  catch(e){
    console.log('no sign url...')
  }

  useEffect(() => {
    setFile(fileToBe)
  },[newSignUrl && newSignUrl])


  let progreeInfoTxt
  if(progress < 100 && loading) progreeInfoTxt = 'Uploading image'
  else if(progress === 100 && loading) progreeInfoTxt = 'Uploaded! - waiting for response from server'



  return(
    <>
      <div className="vrap-video-inner-box">
        { loading && <Loader progress = {progress} text= {progreeInfoTxt}/> }
        { sessionType !== 'Monitoring' && sendFile && !loading &&
        <div className="vrap-parameter-form" >

          <VrapForm sessions={sessions} activeRecordId={activeRecordId}
            setLoading = {setLoading}
            sessionId = {sessionId} dispatchAction={postParameterImage} acceptFormat = 'image/*'
            setSendFile = {setSendFile} formLabel = 'CHOOSE A IMAGE FILE TO UPLOAD'
            progress = {progress} setProgress = {setProgress}
          />
        </div>
        }
        { activeRecord.parametersUrl !== 'notSet' && !loading
              &&  <Image url={file} title="Production Video" className="vrap-image-preview"/>
        }
        {
          activeRecord.parametersUrl === 'notSet' &&
          <div className="flex-center" style={{ height: '100%' }}>
            <p>Parameter image not set yet</p>
          </div>

        }
      </div>
      <div className='vrap-session-backButton-bottom' style={{ width: '100%' }}>
        <IconButton
          className = 'icon-button-secondary hide-in-mobile'
          char={size3Video === 'vrap-video3-normal' ? '4' : '3'}
          text={size3Video === 'vrap-video3-normal' ? 'Enlarge' : 'Shrink'}
          onClick={ () => setSize3Video( size3Video === 'vrap-video3-normal' ? 'vrap-video3-enlarged' : 'vrap-video3-normal')}
        />
        { sessionType === 'Production' ?
          <IconButton
            className = 'icon-button-secondary'
            char="1" text="Upload"
            onClick={() => setSendFile(sendFile ? false : true)}
          /> : <div></div>
        }
      </div>

    </>
  )
}

export default Session3Camera



